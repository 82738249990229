.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.logo {
  /* width: 120px;
  height: 31px;
  background: #333; */
  margin: 0px 24px 2px 0;
  float: left;
}

.document-view {
  width: 100%;
  display: flex;
  justify-content: center;
}
.pdf-view {
  width: 80%;
  height: 100vh;
}

.logo img {
  height: 55px;
}

.header-content .header-content-background {
  background: #fff;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Colour Picker Error highlight */

.ant-form-item-has-error .ant-color-picker-trigger {
  border-color: #ff7875;
}

.form-input-multi-spaced {
  display: flex;
  justify-content: flex-start;
  width: 170%;
  @media (min-width: 320px) {
    /* smartphones, iPhone, portrait 480x320 phones */
    flex-wrap: wrap;
  }
  @media (min-width: 481px) {
    /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
    flex-wrap: wrap;
  }
  @media (min-width: 641px) {
    /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
    flex-wrap: wrap;
  }
}

.site-content-background {
  @media (min-width: 320px) {
    /* smartphones, iPhone, portrait 480x320 phones */
    padding: 16px;
  }
  @media (min-width: 481px) {
    /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
    padding: 16px;
  }
  @media (min-width: 641px) {
    /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
    padding: 20px;
  }
  @media (min-width: 961px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
    padding: 20px;
  }
}
