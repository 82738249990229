body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-content {
  margin-top: 70px;
  padding: 0px 10px 10px 10px;
  min-height: 90vh !important;
}

.ant-layout-footer {
  background-color: #001529 !important;
  color: rgba(255, 255, 255, 0.65) !important;
  padding: 10px 50px !important;
}

.table-action-btn {
  margin-bottom: 10px;
  text-align: right !important;
}

.full-height {
  height: 100%;
}

.space-top {
  margin-top: 20px !important;
}

.space-top-10 {
  margin-top: 10px !important;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.custom-panel-header > .ant-collapse-header {
  justify-content: center;
}

.ant-modal-content {
  height: 100%;
  display: flex;
  flex-direction: column;
}
